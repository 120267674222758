import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from '@material-ui/core';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import EditPictureOrgPropType from '../../PropTypes/EditPictureOrgPropType';
import { updateUpdPicture } from '../../Actions/organizationActions';
import './index.sass';

class EditPictureOrg extends Component {
  state = {
    file: null,
    imageUrl: this.props.pictureData.img || this.props.mainPictureUrl,
    value: 50,
  };

  onDrop = file => {
    this.setState({ file: file[0] });
  };

  setEditorRef = editor => {
    this.editor = editor;
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  cancel = () => {
    this.setState({ file: null });
    if (this.props.changeDisplay) {
      this.props.changeDisplay({ displayPicture: false });
    }

    this.props.onClose();
  };

  handleSubmit = () => {
    try {
      if (this.editor) {
        const canvas = this.editor.getImageScaledToCanvas();
        this.props.updateUpdPicture({
          img: canvas.toDataURL('image/png'),
        });

        this.props.onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleImageRemove = () => this.setState({ imageUrl: null, file: null });

  render() {
    const { file, value, imageUrl } = this.state;
    const source = file || imageUrl;

    return (
      <div className="edit-picture">
        <Dropzone
          className="crop-zone"
          accept="image/jpeg, image/png"
          onDrop={this.onDrop}
          disabled={!!source}
          multiple={false}
        >
          {!source && (
            <div className="drop-info-container">
              <div className="drop-info">
                Drop image file here or click to upload.
              </div>

              <div className="crop-image">
                <label
                  className="upload-fake-button"
                  htmlFor="input-picture"
                >
                  Upload an image
                </label>
                <input
                  disabled={!!source}
                  id="input-picture"
                  className="input-button"
                  type="file"
                />
              </div>
            </div>
          )}

          {source && (
            <>
              <AvatarEditor
                image={source}
                width={300}
                ref={this.setEditorRef}
                scale={(value * 2) / 100}
                height={300}
                borderRadius={300}
              />
              <Slider value={value} aria-labelledby="label" onChange={this.handleChange} />
            </>
          )}
        </Dropzone>

        {source && (
          <>
            <div className="actions-container">
              <button className="button-filled" onClick={this.handleSubmit}>
                Crop and accept
              </button>
            </div>
            <div className="actions-container">
              <button className="remove-button" onClick={this.handleImageRemove}>
                Remove
              </button>
            </div>
          </>
        )}
        <button onClick={this.cancel} className="cancel-button">
          Cancel
        </button>
      </div>
    );
  }
}

EditPictureOrg.propTypes = EditPictureOrgPropType;

const mS = state => ({
  mainPictureUrl: state.organizationReducer.mainPictureUrl,
  pictureData: state.organizationReducer.pictureData,
});

const mD = {
  updateUpdPicture,
  enableRightDrawer,
};

export default connect(
  mS,
  mD,
)(EditPictureOrg);
